@use "styles/sass/abstracts/variables" as var;

.wrapper-profile-partner-section {
	@apply w-fit relative cursor-default;

	&.retracted {
		@apply pr-0;

		.profile-options {
			@apply hidden;
		}
	}

	.profile-name-wrapper {
		@apply flex items-center gap-2 cursor-pointer;
	}

	.profile-options {
		@apply pb-3 absolute left-0 z-10 hidden flex-col w-fit;
		top: calc(-100% - var.$spaces-16);
		padding-right: calc(100% - 100px);

		&::after {
			@apply w-full bg-neutral-high-pure-50 absolute -z-10 bottom-0 left-0 right-0 shadow-2xl;
			height: calc(100% - var.$spaces-16);
		}

		button {
			@apply shadow flex justify-between text-feedback-negative-pure-500 py-3 px-4 bg-neutral-high-pure-50 border-b border-solid text-sm rounded-lg;
			border-color: var.$neutral-high-400;
			white-space: nowrap;

			&.logout {
				@apply text-feedback-negative-pure-500;
			}
		}
	}

	&:hover {
		.profile-options {
			@apply flex;
		}
	}

	&:hover.retracted {
		.profile-options {
			@apply hidden;
		}
	}
}
