@use "styles/sass/abstracts/_variables" as var;
@use "styles/sass/base/typography.scss" as *;

.input-component {
	@apply border border-neutral-high-200 p-4 w-full rounded text-neutral-low-300;

	button {
		width: var.$spaces-24;
		height: var.$spaces-24;
		border: none;
		background-color: transparent;
		cursor: pointer;
	}

	&:focus {
		@apply border border-neutral-low-pure-500;
		box-sizing: border-box;
	}

	&:read-only {
		@apply pr-8;
	}
}

.input-mask {
	width: 100%;
	flex: 1;
	border: none;
	background-color: transparent;
	outline: none;
	font-size: var.$fonts-12;
	color: var.$neutral-low-pure-500;
}

::placeholder {
	color: var.$neutral-low-400;
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active {
	box-shadow: 0 0 0 30px var.$neutral-high-100 inset !important;
	-webkit-text-fill-color: var.$neutral-low-pure-500 !important;
}

.disabled-text {
	color: var.$neutral-high-200 !important;
}

.copy-button {
	position: absolute;
	right: var.$spaces-16;
	top: 50%;
	transform: translateY(-50%);
	background: none;
	border: none;
	cursor: pointer;
}
