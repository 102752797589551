@use "styles/sass/abstracts/variables" as var;

.menu-item {
	@apply w-full flex justify-start items-end flex-row gap-2;
	transition: background-color 0.3s ease, color 0.3s ease;
	padding: var.$spaces-12 var.$spaces-16;
	position: relative;
	border-radius: var.$spaces-8;
	flex-shrink: 0;
	left: 0;
	transform: translateX(0);
	transition: all 0.3s ease;

	&.selected {
		transition: background-color 0.3s ease, color 0.3s ease;
		background: linear-gradient(
				0deg,
				var.$charts-grape-200 0%,
				var.$charts-grape-200 100%
			),
			linear-gradient(
				0deg,
				var.$charts-grape-300 0%,
				var.$charts-grape-300 100%
			),
			var.$charts-grape-300;
		color: var.$branding-highway-low-700;
		font-weight: var.$weight-semibold;
	}

	&.retracted {
		width: fit-content;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.3s ease;
	}
}

.menu-item:hover {
	background-color: var.$neutral-high-400;
}
