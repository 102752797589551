@use "styles/sass/abstracts/variables" as var;

.search-bar-component {
	@apply relative w-full;
	max-width: 608px;

	.search-bar {
		@apply w-full flex flex-row justify-between items-center w-full p-4 gap-4 rounded;
		background-color: var.$neutral-high-pure-50;
		border: 1px solid var.$neutral-high-400;
		height: 56px;

		input {
			@apply w-full;
			color: var.$neutral-low-pure-500;

			&::placeholder {
				color: var.$neutral-high-400;
			}
		}
	}

	.recent-searches {
		@apply w-full absolute flex flex-col gap-2 mt-2 bg-neutral-high-pure-50 rounded py-4 px-2;
		border: 1px solid var.$neutral-high-400;

		.header {
			@apply flex flex-row justify-between items-center px-2 pb-1;
		}

		.search {
			@apply flex justify-between items-center rounded-sm py-1 px-2 cursor-pointer;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.selected,
			&:hover {
				background-color: var.$neutral-high-100;
			}
		}
	}

	&.mobile {
		.recent-searches {
			@apply p-0 mt-6;
			border: 0;
			height: 100vh;

			.header {
				@apply flex-row p-0;
			}

			.search {
				@apply p-0;
			}
		}

		.search-button {
			@apply bg-neutral-high-pure-50 w-full fixed left-0 bottom-0 px-6 py-4;
			border-top: 1px solid var.$neutral-high-400;
		}
	}
}
