@use "styles/sass/abstracts/variables" as var;

.container-loading {
	.container-summary {
		img {
			width: 100%;
			display: block;
		}
	}
}
