@use "styles/sass/abstracts/variables" as var;

.fixed-button {
	width: 100dvw;
	position: fixed;
	bottom: 0;
	transform: translateY(100%);
	transition: transform 0.1s ease-in;
	padding: 1.5rem;
	border: solid 1px var.$neutral-high-pure-50;
	background-color: var.$neutral-high-pure-50;
	z-index: 10;

	&.is-visible {
		transform: translateY(0);
	}

	button {
		width: 100%;
	}
}
