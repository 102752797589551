@use "styles/sass/abstracts/variables" as var;

.sidebar-partner-section {
	height: auto;
	width: fit-content;

	&.mobile {
		height: fit-content;
		width: 100vw;

		.content {
			@apply w-full py-5 px-6;
			position: absolute;
			top: 0;
			transform: translateX(-100%);
			transition: transform 0.3s ease;
			max-width: none;
			z-index: 100;

			.header {
				@apply w-full flex flex-row justify-between items-center;
			}

			.profile {
				@apply w-full flex flex-row justify-between items-center;
			}
		}
	}

	&.mobile-sidemenu-visible .content {
		@apply w-full;
		height: 100vh;
		transform: translateX(0);
		transition: transform 0.3s ease;
		position: fixed;
	}

	&.retracted .content {
		width: 8vw;
		transition: width 0.3s ease;

		.header,
		.profile,
		.retract-sidebar-button {
			width: fit-content;
			left: 50%;
			transform: translateX(-50%);
			transition: left 0.3s ease, transform 0.3s ease;
		}
	}

	.content {
		@apply flex justify-between flex-col h-full gap-6 p-6 bg-neutral-high-pure-50;
		width: 30vw;
		max-width: 400px;
		transition: all 0.3s ease;
		transform: translateX(0);

		.header,
		.profile,
		.retract-sidebar-button {
			position: relative;
			left: 0;
			transform: translateX(0);
			transition: all 0.3s ease;
		}

		.header {
			width: fit-content;
			position: relative;
		}

		.menu-items {
			@apply w-full flex flex-col gap-2;
			position: relative;
		}

		.footer {
			@apply flex flex-col gap-6;
			margin-top: auto;

			.retract-sidebar-button {
				@apply flex justify-start items-center gap-3;
			}
		}
	}

	.client-name {
		@apply w-full text-lg font-semibold;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.mobile-menu {
		@apply w-full z-10 flex flex-row gap-3 p-6 bg-neutral-high-pure-50 border-b-2 border-neutral-high-100;
		position: fixed;
		top: 0;
	}
}
