@use "styles/sass/abstracts/variables" as var;

.wrapper-profile {
	@apply relative pr-4 cursor-default;

	.profile-name-wrapper {
		@apply flex items-center gap-2 cursor-pointer;
	}

	button {
		@apply text-neutral-low-pure-500 font-light flex justify-between;
	}

	.profile-options {
		@apply absolute right-0 z-10 hidden flex-col pt-3;
		width: 13rem;

		&::after {
			content: "";
			@apply h-[calc(100%-0.7rem)] w-full bg-neutral-high-pure-50 absolute -z-10 bottom-0 left-0 right-0 shadow-2xl;
		}

		a,
		button {
			@apply py-3 px-5 border-b border-solid border-neutral-high-100 text-sm;

			&.logout {
				@apply text-feedback-negative-pure-500;
			}
		}

		button {
			@apply border-none;
		}

		.divider {
			@apply border-t border-neutral-high-100 hidden;
		}
	}

	&:hover {
		.profile-options {
			@apply flex;
		}
	}
}
