@use "../abstracts/variables" as var;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Sora", sans-serif;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:-webkit-any-link {
	cursor: pointer;
	text-decoration: none;
}

input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none !important;
}

svg {
	display: inherit;
}

input[type="search"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type="search"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

body.modal-open {
	width: 100%;
	overflow: hidden;
	position: fixed;
}

html,
body,
#root {
	background-color: var.$neutral-high-pure-50;
	height: 100%;
}
