.download-menu {
	@apply fixed top-0 z-[110] right-0 left-0;

	.content {
		@apply w-1/2 z-[120] absolute right-0  top-0 h-screen bg-neutral-high-pure-50 flex items-center justify-center;
	}
	.overlay {
		@apply absolute  top-0 left-0 w-screen h-screen z-[110] bg-neutral-low-pure-500;
	}
}
