@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/typography";

.benefit {
	@apply flex flex-col lg:gap-6 gap-4;

	overflow-wrap: break-word !important;

	&.mobile {
		@apply flex flex-row gap-3 items-start;
	}

	.benefit-content-mobile {
		@apply flex flex-col gap-3;
	}

	.benefit-image {
		> img {
			@apply h-[219px] lg:w-[240px] w-[219px] object-cover aspect-square object-top;
		}

		&.mobile {
			flex-shrink: 0;

			> img {
				height: 64px;
				width: 64px;
				border-radius: var.$spaces-4;
			}
		}
	}

	.tag {
		@apply text-sm text-neutral-low-400;
	}

	.location {
		@apply flex gap-[0.3rem] items-center text-sm;
	}

	.benefit-description {
		@include font-body-regular-small;
	}
}
