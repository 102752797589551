@use "styles/sass/abstracts/variables" as var;

.full-width-button {
	width: 100%;
}

.button-component {
	border: 1px solid transparent;
	@apply px-4 py-0 cursor-pointer items-center justify-center inline-flex;
	transition: transform 0.5s;
	border-radius: var.$spaces-4;

	.first-icon {
		@apply mr-2;
	}

	.last-icon {
		@apply ml-2;
	}

	.button-children {
		display: flex;
		gap: var.$spaces-8;
		align-items: center;
	}

	&.button-small {
		@apply px-6 py-3;
		font-size: var.$fonts-14;

		&.button-icon {
			@apply p-1 h-fit;

			.button-children,
			svg {
				width: 1rem;
				height: 1rem;
			}
		}

		&.button-chip {
			@apply px-4 py-2;
			border-radius: 2rem;

			.button-children {
				font-size: var.$fonts-12;
			}
		}

		.button-children {
			line-height: 150%;
		}
	}

	&.button-medium {
		@apply h-[3rem] min-w-[8.75rem] px-6 py-4;

		&.button-icon {
			@apply p-2 min-w-fit h-fit;

			.button-children,
			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		&.button-chip {
			@apply h-[2.5rem] min-w-[6.375rem] px-4;
			padding-top: 0.625rem;
			padding-bottom: 0.625rem;
			border-radius: 2rem;

			.button-children {
				font-size: var.$fonts-14;
			}
		}

		&.button-text-base {
			.button-children {
				font-size: var.$fonts-16;
			}
		}

		.button-children {
			font-size: var.$fonts-18;
			line-height: 150%;
		}
	}

	&.button-large {
		@apply min-w-[8.75rem] px-8 py-4 h-24;

		&.button-icon {
			@apply p-3 min-w-fit h-fit;

			.button-children,
			svg {
				width: 2rem;
				height: 2rem;
			}
		}

		&.button-chip {
			@apply py-3 px-6 min-w-fit;
			border-radius: 2rem;

			.button-children {
				font-size: var.$fonts-16;
			}
		}

		.button-children {
			font-size: var.$fonts-18;
			line-height: 150%;
		}
	}

	&.button-primary {
		@apply bg-neutral-low-pure-500 text-neutral-high-pure-50 border-neutral-low-pure-500;

		&:hover {
			@apply bg-neutral-low-400 text-neutral-high-pure-50 border-neutral-low-400;
		}

		&.button-disabled {
			@apply bg-neutral-high-100 text-neutral-high-200 border-neutral-high-100 cursor-not-allowed;
		}
	}

	&.button-secondary {
		@apply bg-neutral-high-pure-50 text-neutral-low-pure-500 border-neutral-low-pure-500;

		&:hover {
			@apply bg-neutral-high-100 text-neutral-low-pure-500 border-neutral-low-pure-500;
		}

		&.button-disabled {
			@apply bg-neutral-high-pure-50 text-neutral-high-200 border-neutral-high-200 cursor-not-allowed;
		}

		&.button-chip {
			@apply border-neutral-high-200;
		}
	}

	&.button-ghost {
		@apply bg-neutral-high-100 text-neutral-low-pure-500;

		&:hover {
			@apply bg-neutral-high-100 text-neutral-low-pure-500;
		}

		&.button-disabled {
			@apply bg-neutral-high-pure-50 text-neutral-high-200 cursor-not-allowed opacity-40;
		}

		&.button-is-loading {
			@apply bg-neutral-high-100;
		}
	}

	&.button-link {
		@apply py-0 px-1 text-neutral-low-pure-500;
		background-color: transparent;
		text-decoration: underline;

		&:hover {
			@apply text-neutral-low-400;
		}

		&.button-disabled {
			@apply text-neutral-high-200 cursor-not-allowed;
		}
	}

	.container-loading {
		position: absolute;
		display: none;
	}

	&.button-is-loading {
		@apply cursor-progress;

		.button-children {
			opacity: 0;
		}

		.container-loading {
			display: block;
		}
	}

	&.button-no-padding {
		padding: 0;

		div.button-children {
			width: 100%;
			height: 100%;
		}
	}
}
