@use "styles/sass/abstracts/variables" as var;

.status-tag {
	@apply text-center w-fit;
	padding: var.$spaces-4 var.$spaces-8;
	border-radius: 4px;
	font-size: var.$fonts-12;

	&.benefit {
		@apply font-semibold;
		line-height: 16px;
	}

	&.active {
		color: var.$feedback-positive-pure-500;
		background-color: var.$feedback-positive-high-50;

		&.benefit {
			background-color: var.$feedback-positive-high-50;
			color: var.$neutral-low-pure-500;
		}
	}

	&.inactive,
	&.draft {
		color: var.$neutral-low-pure-500;
		background-color: var.$neutral-high-100;

		&.benefit {
			background-color: var.$neutral-high-300;
		}
	}

	&.deleted,
	&.cancelled {
		color: var.$feedback-negative-pure-500;
		background-color: var.$feedback-negative-high-50;
	}

	&.incomplete,
	&.pending {
		color: var.$feedback-alert-pure-500;
		background-color: var.$feedback-alert-high-50;

		&.benefit {
			background-color: var.$feedback-alert-high-50;
			color: var.$neutral-low-pure-500;
		}
	}
}
