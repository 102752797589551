@use "styles/sass/abstracts/variables" as var;
@use "styles/sass/base/typography" as *;

.otp-group {
	@apply relative flex flex-row justify-start items-center gap-2;

	.otp-input {
		@apply py-4 px-3 border rounded;
		max-width: var.$spaces-56;
		min-width: var.$spaces-24;
		border-color: var.$neutral-high-400;
		@include font-heading-semibold-extra-small;
		text-align: center;
		white-space: nowrap;
		font-size: clamp(var.$fonts-18, 5vw, var.$fonts-24);

		&.error {
			border-color: var.$feedback-negative-pure-500;
		}

		&.mobile {
			@apply px-0;
			flex-shrink: 1;
		}
	}

	> input:focus::placeholder {
		color: transparent;
	}
}
