@use "styles/sass/abstracts/variables" as var;

.Toastify__toast-container {
	margin-top: var.$spaces-40;
}

.Toastify__toast {
	max-width: calc(100% - var.$spaces-40);
	width: 100%;
	margin: var.$spaces-40 auto;
	border-radius: 4px;
	background-color: var.$neutral-low-pure-500;
	padding: var.$spaces-40 var.$spaces-16;

	&.Toastify__toast--warning {
		background-color: var.$feedback-alert-pure-500;
	}

	&.Toastify__toast--error {
		background-color: var.$feedback-negative-pure-500;
	}

	&.Toastify__toast--success {
		background-color: var.$feedback-positive-pure-500;
	}

	&.Toastify__toast-theme--dark {
		.Toastify__progress-bar {
			visibility: visible !important;
			position: absolute;
			top: 0;
			left: 0;
		}

		.progress-bar-custom-error {
			background-color: var.$feedback-negative-pure-500;
		}

		.progress-bar-custom-sucess {
			background-color: var.$feedback-positive-pure-500;
		}

		.Toastify__toast-icon {
			margin-inline-end: 10px;
			width: 30px;
		}

		h1 {
			font-size: var.$fonts-14;
			font-weight: 600;
			line-height: 21px;
		}

		h3 {
			font-size: var.$fonts-12;
			font-weight: 400;
			line-height: 18px;
		}
	}
}

.Toastify__progress-bar {
	visibility: hidden;
}

.Toastify__toast-body {
	padding: 0;
	margin: 0;
}

@media only screen and (max-width: 770px) {
	.Toastify__toast-container {
		margin-top: var.$header-height !important;
	}
}
