@use "styles/sass/abstracts/variables" as var;

.home {
	min-height: calc(100vh - var.$header-height);
	margin-top: var.$header-height;

	@media only screen and (max-width: 1023px) {
		@apply mt-16;
	}

	.page-header {
		.title {
			@apply px-6 pt-10 pb-6 my-0 mx-auto lg:px-[136px] lg:pb-8 lg:pt-40;

			> h1 {
				@apply pb-0 font-extralight text-[4.813rem] leading-[130%] tracking-[-0.1925rem] max-lg:text-[1.625rem] max-lg:tracking-normal max-lg:font-normal;
			}
		}

		.highlight {
			@apply text-branding-highway-high-400 lg:mb-8;
		}

		.banner {
			@apply relative h-[391px] lg:h-[33.75rem] lg:mb-32;

			.banner-image {
				background-color: #e6e7e9;
				height: 100%;

				.image-row {
					@apply flex flex-row items-center justify-between;
				}

				img {
					@apply h-full lg:h-[540px] lg:w-fit w-full object-cover;
				}
			}

			.action-card {
				@apply w-[34rem] -mb-32 absolute bottom-0 bg-neutral-high-pure-50 flex flex-col gap-8 px-16 py-[2.875rem];
				left: calc(max((50dvw - 52rem), 8.5rem));

				h5 {
					@apply text-2xl leading-9 font-normal;
				}

				p {
					@apply leading-6 text-neutral-low-400;
				}

				button {
					@apply w-[15.5rem] h-16 text-lg font-normal rounded-lg;
				}

				@media only screen and (max-width: 1023px) {
					@apply h-fit w-[360px] left-1/2 right-1/2 top-0 my-10 p-6 gap-0;
					transform: translateX(-50%);

					@media screen and (max-width: 360px) {
						width: 312px;
					}

					h5 {
						@apply leading-[1.875rem] text-xl mb-6;
					}

					p {
						@apply text-base mb-8;
					}

					button {
						@apply rounded-[0.25rem] h-12 text-base w-full;
					}
				}
			}
		}
	}

	.cities-container {
		@apply grid md:grid-cols-3 gap-4 mt-6;

		@media only screen and (max-width: 768px) {
			@apply flex flex-row overflow-y-scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			overflow: -moz-scrollbars-none;

			img {
				min-width: 14.5rem;
			}
		}
	}

	.banner-download-app {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		background-color: var.$neutral-high-100;

		> img {
			max-height: 550px;
			width: auto;
		}

		@media screen and (max-width: 1023px) {
			@apply pt-10 px-6;
		}
	}
}
