.container-search {
	@apply fixed left-0 top-16 sm:top-24 h-screen w-screen shadow-lg pointer-events-none;

	.content {
		@apply w-full h-screen bg-neutral-high-pure-50 left-0  shadow-lg overflow-auto pointer-events-auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow: -moz-scrollbars-none;

		&::-webkit-scrollbar {
			display: none;
		}

		.icon-close {
			@apply border-0 border-b border-solid border-neutral-high-100 flex items-center px-6 h-16;
		}

		.wrapper-menu {
			.divider {
				@apply border-t border-neutral-high-100;
			}

			span {
				@apply flex items-center justify-between w-full p-6 text-neutral-low-pure-500 text-sm font-bold;
			}

			ul {
				@apply flex flex-col gap-9 my-8;

				li {
					@apply w-full border-neutral-high-100 px-6;

					h1 {
						@apply font-normal;
					}

					.list-toggle {
						@apply flex items-center gap-2;

						.icon-toggle {
							@apply transition-all ease-in-out transform flex;

							&.active {
								transform: rotate(-180deg);
							}
						}
					}
					.list-wrapper {
						@apply max-h-0 overflow-hidden transition-all duration-500 ease-out flex flex-col mt-2;

						> a {
							@apply px-8 py-4 border-solid border-0 border-b border-neutral-high-100;
						}
					}
				}
			}
		}
	}
}
