@use "styles/sass/abstracts/variables" as var;

.outlet {
	height: calc(100vh - var.$backoffice-header-height);
	margin-top: var.$backoffice-header-height;
	margin-left: auto;
	margin-right: auto;
	max-width: var.$maximum-width;
}

.partner-section-layout {
	@apply flex min-h-screen;
	background-color: var.$neutral-high-300;

	@media screen and (max-width: 1023px) {
		flex-direction: column;
		margin-top: 78px;
	}
}
