/*** SPACING ***/
$spaces-0: 0; //0px
$spaces-4: 0.25rem; //4px
$spaces-8: 0.5rem; //8px
$spaces-10: 0.625rem; //10px
$spaces-12: 0.75rem; //12px
$spaces-16: 1rem; //16px
$spaces-24: 1.5rem; //24px
$spaces-28: 1.75rem; //28px
$spaces-32: 2rem; //32px
$spaces-36: 2.25rem; //36px
$spaces-40: 2.5rem; //40px
$spaces-48: 3rem; //48px
$spaces-56: 3.5rem; //56px
$spaces-64: 4rem; //64px
$spaces-80: 5rem; //80px

$spaces: (
	0: $spaces-0,
	1: $spaces-4,
	2: $spaces-8,
	3: $spaces-16,
	4: $spaces-24,
	5: $spaces-28,
	6: $spaces-32,
	7: $spaces-36,
	8: $spaces-40,
	9: $spaces-48,
	10: $spaces-56,
	11: $spaces-64,
	12: $spaces-80
);

/*** FONTS ***/
$fonts-10: 0.625rem; // 10px
$fonts-12: 0.75rem; // 12px
$fonts-14: 0.875rem; // 14px
$fonts-16: 1rem; // 16px
$fonts-18: 1.125rem; // 18px
$fonts-20: 1.25rem; // 20px
$fonts-24: 1.5rem; // 24px
$fonts-28: 1.75rem; // 28px
$fonts-32: 2rem; // 32px
$fonts-36: 2.25rem; // 36px
$fonts-40: 2.5rem; // 40px
$fonts-48: 3rem; // 48px
$fonts-64: 4rem; // 64px
$fonts-80: 5rem; // 80px
$fonts-96: 6rem; // 96px

$fonts-body: (
	tiny: $fonts-10,
	extra-small: $fonts-12,
	small: $fonts-14,
	medium: $fonts-16,
	large: $fonts-18
);

$fonts-heading: (
	tiny: $fonts-20,
	extra-small: $fonts-24,
	small: $fonts-28,
	medium: $fonts-32,
	large: $fonts-36,
	extra-large: $fonts-40
);

$fonts-display: (
	tiny: $fonts-48,
	small: $fonts-64,
	medium: $fonts-80,
	large: $fonts-96
);

$line-small: 125%;
$line-regular: 150%;

$line: (
	small: $line-small,
	regular: $line-regular
);

$weight-light: 300;
$weight-regular: 400;
$weight-semibold: 600;
$weight-bold: 700;

$font_weights: (
	light: $weight-light,
	regular: $weight-regular,
	semibold: $weight-semibold,
	bold: $weight-bold
);

$letter-spacing-lg: 0.1rem;

/*** COLORS ***/
$neutral-low-300: #999999;
$neutral-low-400: #666666;
$neutral-low-pure-500: #1d1d1d;

$neutral-high-pure-50: #ffffff;
$neutral-high-100: #f0f0f0;
$neutral-high-200: #dbdbdb;
$neutral-high-300: #ececed;
$neutral-high-400: #c6c7c8;

$feedback-positive-low-950: #1a2f2a;
$feedback-positive-pure-500: #00d4a1;
$feedback-positive-high-50: #e5fbf6;

$feedback-alert-low-950: #322e21;
$feedback-alert-pure-500: #f6c643;
$feedback-alert-high-50: #fffaea;

$feedback-negative-low-950: #331f21;
$feedback-negative-pure-500: #f83446;
$feedback-negative-high-50: #feebec;

$feedback-focus-low-950: #272732;
$feedback-focus-pure-500: #8887f3;
$feedback-focus-high-50: #f3f3fe;

$branding-highway-low-950: #231d2e;
$branding-highway-low-900: #2a1d3f;
$branding-highway-low-800: #371c62;
$branding-highway-low-700: #441c85;
$branding-highway-low-600: #511ca7;
$branding-highway-pure-500: #5e1cca;
$branding-highway-high-400: #7e49d5;
$branding-highway-high-300: #9e77df;
$branding-highway-high-200: #bfa4ea;
$branding-highway-high-100: #dfd2f4;
$branding-highway-high-50: #efe8fa;

$charts-grape-100: #f5efff;
$charts-grape-200: #e9dbff;
$charts-grape-300: #d3b7ff;
$charts-grape-400: #bd93fe;
$charts-grape-500: #a76ffe;
$charts-grape-600: #8b53e2;
$charts-grape-700: #683ea9;
$charts-grape-800: #452971;
$charts-grape-900: #231538;

$colors: (
	neutral: (
		"low-300": $neutral-low-300,
		"low-400": $neutral-low-400,
		"low-pure-500": $neutral-low-pure-500,
		"high-pure-50": $neutral-high-pure-50,
		"high-100": $neutral-high-100,
		"high-200": $neutral-high-200,
		"high-300": $neutral-high-300
	),
	"feedback-positive": (
		"low-950": $feedback-positive-low-950,
		"pure-500": $feedback-positive-pure-500,
		"high-50": $feedback-positive-high-50
	),
	"feedback-alert": (
		"low-950": $feedback-alert-low-950,
		"pure-500": $feedback-alert-pure-500,
		"high-50": $feedback-alert-high-50
	),
	"feedback-negative": (
		"low-950": $feedback-negative-low-950,
		"pure-500": $feedback-negative-pure-500,
		"high-50": $feedback-negative-high-50
	),
	"feedback-focus": (
		"low-950": $feedback-focus-low-950,
		"pure-500": $feedback-focus-pure-500,
		"high-50": $feedback-focus-high-50
	),
	"branding-highway": (
		"low-950": $branding-highway-low-950,
		"low-900": $branding-highway-low-900,
		"low-800": $branding-highway-low-800,
		"low-700": $branding-highway-low-700,
		"low-600": $branding-highway-low-600,
		"pure-500": $branding-highway-pure-500,
		"high-400": $branding-highway-high-400,
		"high-300": $branding-highway-high-300,
		"high-200": $branding-highway-high-200,
		"high-100": $branding-highway-high-100,
		"high-50": $branding-highway-high-50
	),
	"charts-grape": (
		"100": $charts-grape-100,
		"200": $charts-grape-200,
		"300": $charts-grape-300,
		"400": $charts-grape-400,
		"500": $charts-grape-500,
		"600": $charts-grape-600,
		"700": $charts-grape-700,
		"800": $charts-grape-800,
		"900": $charts-grape-900
	)
);

/*** DIRECTIONS ***/
$directions: (
	l: "left",
	r: "right",
	t: "top",
	b: "bottom"
);

/*** LAYOUTS ***/
$header-height: 6rem;
$backoffice-header-height: 4rem;
$maximum-width: 1920px;
