@use "styles/sass/abstracts/variables" as var;

.section-container {
	@apply border border-solid border-neutral-high-200 rounded-lg;
	border-radius: 8px;
}
.section-container:not(:last-child) {
	@apply mb-8;
}

.section-header {
	@apply flex justify-between items-center;
	@apply bg-neutral-high-100;
	@apply p-4 pl-8 pr-8 text-sm font-semibold;

	border-radius: 8px 8px 0px 0px;
	border-bottom: 1px solid var.$neutral-high-200;
}

.section-footer {
	@apply bg-neutral-high-100;
	@apply p-6 text-sm;
}

.section-content {
	@apply lg:p-8 text-sm;
}
